import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { isNullOrWhitespace } from "../../utils/HelperFunctions"

const CompricerPage = ({ location }) => {
  useEffect(() => {
    if (isNullOrWhitespace(location.search)) {
      navigate("/")
      return
    }

    navigate(`/s?${new URLSearchParams(location.search).toString()}`)
  }, [location.search])

  return <></>
}

export default CompricerPage
